<template>

    <div>

        <Breadcrumb :history="data.history || []" :title="data.title || 'You are here'" :crumbs-visible="breadcrumb.crumbsVisible" :show-current="breadcrumb.showCurrent" :more-text="breadcrumb.moreText"></Breadcrumb>

        <component v-if="component" :is="component" :data="data" @applyAction="applyAction" />

    </div>

</template>

<script>

import localStore from '../localStore'

import Breadcrumb from '@/components/Breadcrumb.vue'

import { mapState, mapActions } from 'vuex'

export default {

    name: 'VaState',

    components: {
        Breadcrumb
    },

    data () {

        return {

            component: null,

            breadcrumb: {
                crumbsVisible: 0,
                showCurrent: false,
                moreText: '...'
            }

        }
    },

    computed: {

        ...mapState(['vastate', 'data']),

        loader () {

            let vastate = this.vastate || null;

            let map = {
                'step'       : 'VaStep',
                'decision'   : 'VaDecision',
                'record'     : 'VaRecord',
                'records'    : 'VaRecords',
                'summary'    : 'VaSummary'
            };

            let cmp = map[vastate] || 'Va404';

            return () => import(`@/components/${cmp}.vue`);
        }
    },

    beforeRouteEnter (to, from, next) {

        let stateid = localStore.getStateid(to.params.guid || '');

        if ( !stateid ) { next({ path: '/notfound', replace: true }); return; }

        next(_this => { _this.loadState(stateid); });
    },

    beforeRouteUpdate (to, from, next) {

        let stateid = localStore.getStateid(to.params.guid || '');

        if ( !stateid ) { next({ path: '/notfound', replace: true }); return; }

        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.loadState(stateid);

        next();
    },

    watch: {

        data(data) {

            data['stateid'] = parseInt(this.stateid, 10) || 0;

            this.setData(this.stateid, data);
        },

        '$screen.width': {
            immediate: true,
            handler() {
                const screenWidth = this.$screen.width;

                this.breadcrumb.showCurrent = false;
                this.breadcrumb.crumbsVisible = 0;
                this.breadcrumb.moreText = 'Back to ...';

                if (screenWidth >= 400) {
                    this.breadcrumb.moreText = '';
                    this.breadcrumb.showCurrent = true;
                }
                if (screenWidth >= 450) {
                    this.breadcrumb.crumbsVisible = 1;
                }
            }
        }

    },

    methods: {

        ...mapActions(['loadState', 'applyAction']),

        setData (stateid, data) {

            let title = data.title ? `| ${data.title}` : '';

            document.title = `Telkom ${title}`;

            this.loader()
                .then( () => { this.component = () => this.loader(); })
                .catch(() => { this.component = () => import('@/components/Va404.vue'); });
        }

    }

}
</script>
