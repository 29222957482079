<template>
  <nav>

    <ul class="flex pt-8 pr-8 pb-4 pl-8">

      <!-- Home icon -->
      <li class="mr-4"><a tabindex="0" @click="$router.push({name:'home'})" class="cursor-pointer" v-tooltip="tip('Go to the Home Screen')"><i class="fa fa-home text-2xl"></i></a></li>

      <!-- More dropdown button -->
      <li v-if="hasMore" class="li-chevron-right">
          <button tabindex="0" @click="showMore = !showMore" :class="{'bg-telkom-blue text-white': showMore, 'text-telkom-blue bg-telkom-blue-lightest hover:text-white': !showMore}" class="px-3 py-2 leading-none flex items-center cursor-default whitespace-no-wrap rounded text-xs hover:bg-telkom-blue-dark">
            <span class="pr-3 font-bold" v-text="moreDropdownText"></span>
            <i class="fa fa-chevron-down animated faster" :class="{'rotate0to180': showMore, 'rotate180to0': !showMore}"></i>
          </button>
      </li>

      <!-- Recent crumbs -->
      <li v-for="(item, key) in crumbs" :key="key" class="truncate-left text-telkom-blue max-w-xs li-chevron-right" v-tooltip="tip(`Jump back to:<br>${item.question}`)">
        <a :tabindex="0" @click="historyJump(item.action)" class="cursor-pointer" v-html="`${item.question}&lrm;`"></a>
      </li>

      <!-- Current crumbs -->
      <li v-if="showCurrent" class="li-chevron-right truncate-left text-telkom-blue-lighter"><span class="text-telkom-blue-lighter font-bold" v-html="`${title}&lrm;`"></span></li>

    </ul>

    <!-- History timeline expandable -->
    <div v-if="showMore || showMoreAnimating" class="overflow-hidden">
      <div class="bg-telkom-blue overflow-hidden px-4 py-2 animated faster" :class="{'fadeIn': showMore, 'slideOutUp': !showMore}">
        <history-timeline class="animated faster" :class="{'slideInDown': showMore}" :history="history" @action="historyJump"></history-timeline>
      </div>
    </div>

  </nav>
</template>

<script>

import HistoryTimeline from '@/components/HistoryTimeline.vue'

import { sdk } from '@/sdk.js'

import { mapActions } from 'vuex'

export default {

  name: 'Breadcrumb',

  components: {
    HistoryTimeline
  },

  data() {
    return {
      showMore: false,
      showMoreAnimating: false
    }
  },

  props: {
    history: { type: Array, default: () => [] },
    title: { type: String, default: 'You are here' },
    moreText: { type: String, default: '' },
    crumbsVisible: { type: Number, default: 1 },
    showCurrent: { type: Boolean, default: true },
  },

  computed: {

    historyReversed() {
      return this.history.slice().reverse();
    },

    hasMore() {
      return this.history.length > this.crumbsVisible;
    },

    hasMoreTotal() {
      return this.hasMore ? (this.history.length - this.crumbsVisible) : 0;
    },

    crumbs() {
      let piece = this.crumbsVisible ? -this.crumbsVisible : this.historyReversed.length;
      return this.hasMore ? this.historyReversed.slice(piece) : this.historyReversed;
    },

    moreDropdownText() {
      return this.moreText.trim() === ''
           ? `+ ${this.history.length} more ...`
           : this.moreText;
    }
  },

  watch: {
    showMore(value) {
      this.showMoreHandler({delay: 300, value: value});
    }
  },

  methods: {

    ...mapActions(['applyAction']),

    showMoreHandler(opts) {
      if (this.showMoreAnimating) { return; } else { this.showMoreAnimating = true; }
      let delay  = opts.delay  || 0;
      let value  = opts.value  || false;
      let toggle = opts.toggle || false;
      this.showMore = toggle ? !this.showMore : value;
      setTimeout(() => { this.showMoreAnimating = false; }, delay);
    },

    historyJump(action) {
      this.closeMore();
      this.applyAction(action);
    },

    closeMore() {
      // this.showMore = false;
      this.showMoreHandler({delay: 0, value: false});
    },

    tip(content) {
      return {
        content,
        delay: { show: 1000, hide: 0 }
      };
    },

    loadState(stateid) {

      return sdk.load(stateid)

        .then(function(resp) {

          let data  = resp.data  || {};
          let error = resp.error || data.error || false;

          if (error) {

            error = error.message ? error : { message: 'An error occurred' };

            return { error };
          }

          return { data };
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.truncate-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
}

.li-chevron-right {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1rem;

  &:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f054"; // <- fa-chevron-right
    color: #99cde7; // <- text-telkom-blue-lighter
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
    font-size: inherit;
    position: absolute;
    top: .35rem;
    left: 0;
  }
}

</style>
