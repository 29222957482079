<template>
  <div class="text-sm m-6 pl-4 border-l-2 xborder-telkom-blue-lighter">

    <template v-for="(item, key) in history">

      <a tabindex="0" @click="$emit('action', item.action)" class="flex text-left cursor-pointer text-white hover:no-underline hover:text-white hover:bg-telkom-blue-dark focus:no-underline focus:text-white focus:bg-telkom-blue-dark" :key="key">

        <div class="relative pr-4">
          <svg class="absolute" style="top:13px;left:-20px;" width="10" height="10" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50" class="fill-current"/></svg>
        </div>

        <div class="pt-2 pb-4 pr-4">
          <div>{{ item.question }}</div>
          <div class="flex items-center text-telkom-blue-lighter mt-2 text-xs">
            <i class="fa fa-clock-o pr-2"></i>
            <timeago :datetime="item.datetime" :auto-update="60" class="font-sans font-thin"></timeago>
          </div>
        </div>

      </a>

    </template>

  </div>
</template>

<script>
export default {

  name: 'HistoryTimeline',

  props: {
    history: { type: Array, default: () => [] }
  }

}
</script>
